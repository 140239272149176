import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import ScrollLayout from "../../components/ScollLayout"

export default function PersonPage() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleClick = () => {
    setTimeout(() => {
      navigate("/onboarding/ideas")
    }, 500)
  }

  return (
    <ScrollLayout>
      <div className="flex h-full w-full max-w-full flex-col gap-16">
        <div className="custom-container-full top-0 shrink-0 bg-white py-8">
          <h1 className="heading">{t("pages.onboarding.person.heading")}</h1>
        </div>

        <div className="custom-container-full justify-top flex grow flex-col items-center space-y-5 pb-8">
          <button
            className="button-rounded-full border-custom-gray"
            onClick={handleClick}
          >
            <span className="mr-1 pr-2">🚀</span>
            {t("pages.onboarding.person.option1")}
          </button>

          <button
            className="button-rounded-full border-custom-gray"
            onClick={handleClick}
          >
            <span className="mr-1 pr-2">🔬</span>
            {t("pages.onboarding.person.option2")}
          </button>
        </div>
      </div>
    </ScrollLayout>
  )
}
