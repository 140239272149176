import * as Sentry from "@sentry/react"
import {
  BackendGeolocation,
  BackendNetAmount,
  StripePaymentMethodType,
} from "src/types"

const backendApiBaseUrl = "https://api.asanarebel.com/api/v1/"

export async function getGeolocation(): Promise<BackendGeolocation> {
  const response = await fetch(backendApiBaseUrl + "geolocation")

  const geolocation = (await response.json()) as BackendGeolocation

  return geolocation
}

export async function getNetAmount({
  countryCode,
  currency,
  grossAmount,
  paymentMethodType,
}: {
  countryCode: string
  currency: string
  grossAmount: number
  paymentMethodType: StripePaymentMethodType
}): Promise<number | undefined> {
  try {
    const response = await fetch(backendApiBaseUrl + "net_amounts", {
      body: JSON.stringify({
        country_code: countryCode,
        currency,
        gross_amount: grossAmount,
        payment_method_type: paymentMethodType,
      }),
      headers: { "Content-Type": "application/json" },
      method: "POST",
    })

    const netAmount = (await response.json()) as BackendNetAmount

    return netAmount.net_amount
  } catch {
    Sentry.captureException(
      `Fetching net amount failed with countryCode=${countryCode}, currency=${currency}, grossAmount=${grossAmount}, paymentMethodType=${paymentMethodType}`,
    )

    return undefined
  }
}
