import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import Button from "../../components/Button"
import Typewriter from "../../components/Typewriter"
import ScrollLayout from "../../components/ScollLayout"

export default function LearningPage() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleClick = () => {
    navigate("/onboarding2/topic1")
  }

  return (
    <ScrollLayout>
      <div className="flex h-full flex-col text-center custom-container">
        <div className="flex grow flex-col items-center justify-center gap-8 px-8">
          <h1 className="text-8xl">🎉</h1>
          <Typewriter text={t("pages.onboarding.learning.title")} />
        </div>

        <div className="custom-container shrink-0 max-w-sm py-8">
          <Button onClick={handleClick} title={t("shared.continue")} />
        </div>
      </div>
    </ScrollLayout>
  )
}
