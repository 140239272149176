import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import useLocalStorage from "react-use/lib/useLocalStorage"
import ScrollLayout from "../../components/ScollLayout"

export default function AreaPage() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [value, ,] = useLocalStorage<string | null>("selectedTopic", null)

  const handleClick = () => {
    setTimeout(() => {
      navigate("/onboarding2/concepts")
    }, 500)
  }

  const emojis = [
    ["🌌", "🧬", "🧪", "🔭", "🌍"], // science
    ["📜", "🔍", "🌟", "❤️"], // literature
    ["💹", "💼", "🌱", "📉"], // eco
    ["🌟", "️️️🕰️", "🤔", "💡"], // philo
    ["🖼️ ", "🎶", "🎭", "🎬"], //  art
  ]

  function getIndex() {
    switch (value) {
    case "science":
      return 0
    case "literature":
      return 1
    case "economics":
      return 2
    case "philosophy":
      return 3
    case "art":
      return 4
    default:
      return 0
    }
  }

  return (
    <ScrollLayout>
      <div className="custom-container flex h-full flex-col gap-16">
        <div className="shrink-0">
          <h1 className="heading">
            {t(`pages.onboarding.${value}Area.heading`)}
          </h1>
        </div>

        <div className="justify-top flex grow flex-col space-y-5 pb-8">
          {emojis[getIndex()].map((emoji, index) => (
            <button
              key={index}
              className="button-rounded-full border-custom-gray"
              onClick={handleClick}
            >
              <span className="mr-1 pr-2">{emoji}</span>
              {t(`pages.onboarding.${value}Area.topic${index + 1}`)}
            </button>
          ))}
        </div>
      </div>
    </ScrollLayout>
  )
}
