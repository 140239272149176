import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition,
} from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/20/solid"

interface Props {
  text: string;
  title: string;
}

export default function FrequentlyAskedQuestion({ text, title }: Props) {
  return (
    <div className="rounded-xl bg-black p-4">
      <Disclosure>
        {({ open }) => (
          <>
            <DisclosureButton className="flex w-full items-center justify-between gap-5 text-left">
              <span className="grow font-bold font-oswald text-white">{title}</span>

              <ChevronDownIcon className={`w-5 ${open && "rotate-180"} text-white`} />
            </DisclosureButton>

            <div className="overflow-hidden">
              <Transition
                enter="duration-200 ease-out"
                enterFrom="opacity-0 -translate-y-6"
                enterTo="opacity-100 translate-y-0"
                leave="duration-300 ease-out"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 -translate-y-6"
              >
                <DisclosurePanel className="mt-4 text-gray-400 font-sentient">
                  {text}
                </DisclosurePanel>
              </Transition>
            </div>
          </>
        )}
      </Disclosure>
    </div>
  )
}
