import { useTranslation } from "react-i18next"
import apple from "@assets/appleTrust.png"
import google from "@assets/googleTrust.png"
import MovingGeniuses from "./MovingGeniuses"

export default function Ad() {
  const { t } = useTranslation()

  return (
    <>
      <div className="flex flex-col justify-center items-center gap-4">
        <p className="heading mb-8">{t("components.ad.heading")}</p>


        <div className="flex flex-row gap-8 justify-center pb-4">
          <img src={apple} alt="Apple logo" className="w-1/4 sm:w-1/6 object-scale-down" />
          <img src={google} alt="Apple logo" className="w-1/4 sm:w-1/6 object-scale-down" />
        </div>

        <p className="text-2xl font-sentient">
          {t("components.ad.has")}
        </p>

        <p className="text-2xl font-sentient font-bold">
          {t("components.ad.subscribers")}
        </p>
        <p className="text-2xl">⭐⭐⭐⭐⭐</p>

      </div>

      <MovingGeniuses />
    </>
  )
}
