import { useTranslation } from "react-i18next"

interface Props {
  emoji: string;
  setSelectedTopic: (selectedTopic: string) => void;
  topic: string;
}

export default function SingleTopicButton({
  emoji,
  setSelectedTopic,
  topic,
}: Props) {
  const { t } = useTranslation()

  const className = "button-rounded-multiline break-words border-custom-gray bg-transparent text-black"

  const handleClick = (topic: string) => {
    setSelectedTopic(topic)
  }

  return (
    <button className={className} onClick={() => handleClick(topic)}>
      <div className='text-3xl py-2'>{emoji}</div>
      {t(`pages.onboarding.topics.${topic}`)}
    </button>
  )
}
