import { getSession } from "../helpers/supabase"

export interface IsUserSignedInLoaderData {
  isUserSignedIn: boolean;
}

export default async function isUserSignedInLoader(): Promise<IsUserSignedInLoaderData> {
  const { data } = await getSession()

  return {
    isUserSignedIn: data.session !== null,
  }
}
