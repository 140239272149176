import {
  getSession,
  listStripeCoupons,
  listStripePrices,
} from "@helpers/supabase";
import { defer, redirect } from "react-router-dom";
import { StripeCoupon, StripePrice } from "src/types";
import getSubscriptionStatus from "../helpers/getSubscriptionStatus";
import { getUserCurrency } from "../helpers/sessionStorage";

export interface GetPricesLoaderResult {
  getPricesData: GetPricesData;
}

export interface GetPricesData {
  currency: string;
  introPriceCoupon: StripeCoupon | undefined;
  subscriptionStatus: "active" | "inactive" | "none";
  weeklyPrice: StripePrice | undefined;
  yearlyPrice: StripePrice | undefined;
}

export default function getPricesLoader() {
  const getPricesPromise = getPrices();

  return defer({
    getPricesData: getPricesPromise,
  });
}

async function getPrices(): Promise<GetPricesData | Response> {
  const [{ data }, stripeCoupons, stripePrices] = await Promise.all([
    getSession(),
    listStripeCoupons(),
    listStripePrices(),
  ]);

  const currency = await findCurrency({ stripePrices });

  const userId = data?.session?.user?.id;

  if (!userId) {
    return redirect("/sign-up");
  }

  const subscriptionStatus = await getSubscriptionStatus(userId);

  return {
    currency: currency,
    introPriceCoupon: findIntroPriceCoupon({ stripeCoupons }),
    subscriptionStatus: subscriptionStatus,
    weeklyPrice: findPrice({ interval: "week", stripePrices }),
    yearlyPrice: findPrice({ interval: "year", stripePrices }),
  };
}

async function findCurrency({
  stripePrices,
}: {
  stripePrices: StripePrice[];
}): Promise<string> {
  const userCurrency = await getUserCurrency();

  const stripePriceCurrencies = stripePrices.flatMap((price) => [
    price.currency,
    ...Object.keys(price?.currency_options),
  ]);

  return stripePriceCurrencies.includes(userCurrency) ? userCurrency : "eur";
}

function findIntroPriceCoupon({
  stripeCoupons,
}: {
  stripeCoupons: StripeCoupon[];
}): StripeCoupon | undefined {
  return stripeCoupons.find((coupon) => coupon.id === "intro-price-2");
}

function findPrice({
  interval,
  stripePrices,
}: {
  interval: "week" | "year";
  stripePrices: StripePrice[];
}): StripePrice | undefined {
  return stripePrices.find((price) => price.recurring.interval === interval);
}