import austen from "@assets/austen.png"
import einstein from "@assets/einstein.png"
import gogh from "@assets/gogh.png"
import kafka from "@assets/kafka.png"
import curie from "@assets/curie.png"
import newton from "@assets/newton.png"

export default function GeniusImagesList({
  ariaHidden = "false",
}: {
  ariaHidden?: "false" | "true";
}) {
  const geniusImages = [
    { alt: "Austen", src: austen },
    { alt: "Gogh", src: gogh },
    { alt: "Einstein", src: einstein },
    { alt: "Kafka", src: kafka },
    { alt: "Curie", src: curie },
    { alt: "Netwon", src: newton },
  ]

  return (
    <div className="flex flex-col gap-8 pt-8">
      <ul
        aria-hidden={ariaHidden}
        className="animate-infinite-scroll-back flex items-center justify-center md:justify-start [&_img]:max-w-none [&_li]:mx-4"
      >
        {geniusImages.reverse().map((geniusImage, index) => (
          <li key={index}>
            <img
              alt={geniusImage.alt}
              height={183}
              src={geniusImage.src}
              width={183}
            />
          </li>
        ))}
      </ul>
      <ul
        aria-hidden={ariaHidden}
        className="animate-infinite-scroll flex items-center justify-center md:justify-start [&_img]:max-w-none [&_li]:mx-4"
      >
        {geniusImages.map((geniusImage, index) => (
          <li key={index}>
            <img
              alt={geniusImage.alt}
              height={183}
              src={geniusImage.src}
              width={183}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}