import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import ScrollLayout from "../../components/ScollLayout"

export default function DescribePage() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleClick = () => {
    setTimeout(() => {
      navigate("/onboarding/scrolling")
    }, 500)
  }

  return (
    <ScrollLayout>
      <div className="flex h-full flex-col gap-16">
        <div className="shrink-0">
          <h1 className="heading">{t("pages.onboarding.describe.heading")}</h1>
        </div>

        <div className="justify-top flex grow flex-col items-center space-y-5 pb-8">
          <button
            className="button-rounded-full border-custom-gray"
            onClick={handleClick}
          >
            <span className="mr-1 pr-2">🧑‍🎓</span>
            {t("pages.onboarding.describe.student")}
          </button>

          <button
            className="button-rounded-full border-custom-gray"
            onClick={handleClick}
          >
            <span className="mr-1 pr-2">🧍</span>
            {t("pages.onboarding.describe.professional")}
          </button>

          <button
            className="button-rounded-full border-custom-gray"
            onClick={handleClick}
          >
            <span className="mr-1 pr-2">🧑‍🍼</span>
            {t("pages.onboarding.describe.parent")}
          </button>

          <button
            className="button-rounded-full border-custom-gray"
            onClick={handleClick}
          >
            <span className="mr-1 pr-2">🙋</span>
            {t("pages.onboarding.describe.learner")}
          </button>

          <button
            className="button-rounded-full border-custom-gray"
            onClick={handleClick}
          >
            <span className="mr-1 pr-2">🧑‍🏫</span>
            {t("pages.onboarding.describe.teacher")}
          </button>

          <button
            className="button-rounded-full border-custom-gray"
            onClick={handleClick}
          >
            <span className="mr-1 pr-2">💁</span>
            {t("pages.onboarding.describe.other")}
          </button>
        </div>
      </div>
    </ScrollLayout>
  )
}
