import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Navigate, useLoaderData, useNavigate } from "react-router-dom"
import Button from "../../components/Button"
import PasswordInput from "../../components/PasswordInput"
import { setPasswordInSessionStorage } from "../../helpers/sessionStorage"
import { validatePassword } from "../../helpers/validations"
import { IsUserSignedInLoaderData } from "../../loaders/isUserSignedInLoader"
import ScrollLayout from "../../components/ScollLayout"

export default function PasswordPage() {
  const [password, setPassword] = useState("")
  const [passwordValid, setPasswordValid] = useState(true)

  const { isUserSignedIn } = useLoaderData() as IsUserSignedInLoaderData
  const navigate = useNavigate()
  const { t } = useTranslation()

  if (isUserSignedIn) {
    return <Navigate to="/checkout/plans" />
  }

  const isPasswordValid = validatePassword(password)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (isPasswordValid) {
      setPasswordInSessionStorage(password)
      navigate("/sign-up/email")
    } else {
      setPasswordValid(false)
    }
  }

  return (
    <ScrollLayout>
      <div className="flex h-full flex-col custom-container">
        <div className="grow space-y-6 text-center">
          <h1 className="heading">{t("pages.signUp.password.heading")}</h1>

          <p className={passwordValid ? "subheading" : "subheading-error"}>
            {t("pages.signUp.password.subheading")}
          </p>
        </div>

        <form
          className="flex grow flex-col justify-between gap-4"
          onSubmit={handleSubmit}
        >
          <PasswordInput
            autoFocus
            invalid={!passwordValid}
            onChange={(event) => setPassword(event.target.value)}
          />

          <Button
            disabled={password.length < 1}
            type="submit"
            title={t("shared.continue")}
          />
        </form>
      </div>
    </ScrollLayout>
  )
}
