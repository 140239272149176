import { Navigate, Outlet } from "react-router-dom"
import accountRoutes from "./account/accountRoutes"
import checkoutRoutes from "./checkout/checkoutRoutes"
import ErrorPage from "./ErrorPage"
import onboardingRoutes from "./onboarding/onboardingRoutes"
import onboarding2Routes from "./onboarding2/onboarding2Routes"
import signUpRoutes from "./signUp/signUpRoutes"

const routes = [
  {
    path: "/",
    element: <Outlet />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to="onboarding" replace />,
      },
      {
        path: "account",
        element: <Outlet />,
        children: accountRoutes,
      },
      {
        path: "checkout",
        element: <Outlet />,
        children: checkoutRoutes,
      },
      {
        path: "onboarding",
        element: <Outlet />,
        children: onboardingRoutes,
      },
      {
        path: "onboarding2",
        element: <Outlet />,
        children: onboarding2Routes,
      },
      {
        path: "sign-up",
        element: <Outlet />,
        children: signUpRoutes,
      },
    ],
  },
]

export default routes
