import { useEffect, useState } from "react"

interface Props {
  text: string
  onFinish?: () => void
}

export default function Typewriter({ text, onFinish }: Props ) {
  const [currentText, setCurrentText] = useState("")
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex])
        setCurrentIndex((prevIndex) => prevIndex + 1)
      }, 60)

      return () => clearTimeout(timeout)
    } else {
      if (onFinish) {
        onFinish()
      }
    }
  }, [currentIndex, onFinish, text])

  return (
    <div className="items-center justify-center relative">
      <h1 className="font-sentient font-bold text-3xl px-8 absolute">
        {currentText}
      </h1>
      <h1 className="font-sentient font-bold text-3xl px-8 opacity-0">
        {text}
      </h1>
    </div>
  )
}