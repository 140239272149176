import { useTranslation } from "react-i18next"
import BulletPoint from "./BulletPoint"

export default function Features() {
  const { t } = useTranslation()

  return (
    <div className="space-y-11">
      <BulletPoint
        title={t("pages.checkout.plans.bulletPoints.bullet1Title")}
        text={t("pages.checkout.plans.bulletPoints.bullet1Text")}
      />

      <BulletPoint
        title={t("pages.checkout.plans.bulletPoints.bullet2Title")}
        text={t("pages.checkout.plans.bulletPoints.bullet2Text")}
      />

      <BulletPoint
        title={t("pages.checkout.plans.bulletPoints.bullet3Title")}
        text={t("pages.checkout.plans.bulletPoints.bullet3Text")}
      />

      <BulletPoint
        title={t("pages.checkout.plans.bulletPoints.bullet4Title")}
        text={t("pages.checkout.plans.bulletPoints.bullet4Text")}
      />
    </div>
  )
}
