import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import ScrollLayout from "../../components/ScollLayout"

export default function InterestsPage() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleClick = () => {
    setTimeout(() => {
      navigate("/onboarding/inspiration")
    }, 500)
  }

  return (
    <ScrollLayout>
      <div className="flex h-full flex-col gap-16">
        <div className="shrink-0">
          <h1 className="heading">{t("pages.onboarding.interests.heading")}</h1>
        </div>

        <div className="justify-top flex grow flex-col items-center space-y-5 pb-8">
          <button
            className="button-rounded-full border-custom-gray"
            onClick={handleClick}
          >
            <span className="mr-1 pr-2">🤔</span>
            {t("pages.onboarding.interests.newKnowledge")}
          </button>

          <button
            className="button-rounded-full border-custom-gray"
            onClick={handleClick}
          >
            <span className="mr-1 pr-2">🧠</span>
            {t("pages.onboarding.interests.exerciseBrain")}
          </button>

          <button
            className="button-rounded-full border-custom-gray"
            onClick={handleClick}
          >
            <span className="mr-1 pr-2">🎯</span>
            {t("pages.onboarding.interests.exploreTopics")}
          </button>

          <button
            className="button-rounded-full border-custom-gray"
            onClick={handleClick}
          >
            <span className="mr-1 pr-2">🏫</span>
            {t("pages.onboarding.interests.excelSchool")}
          </button>

          <button
            className="button-rounded-full border-custom-gray"
            onClick={handleClick}
          >
            <span className="mr-1 pr-2">📚</span>
            {t("pages.onboarding.interests.refreshKnowledge")}
          </button>

          <button
            className="button-rounded-full border-custom-gray"
            onClick={handleClick}
          >
            <span className="mr-1 pr-2">👀</span>
            {t("pages.onboarding.interests.else")}
          </button>
        </div>
      </div>
    </ScrollLayout>
  )
}
