import ReactConfetti from "react-confetti"
import useWindowSize from "react-use/lib/useWindowSize"

interface Props {
  onFinish: () => void
}

// eslint-disable-next-line react/prop-types
export const Confetti: React.FC<Props> = ({ onFinish }) => {
  const { width, height }: { width: number, height: number } = useWindowSize()
  return (
    <ReactConfetti
      width={width}
      height={height}
      colors={["#D6CFFE", "#FDD5D5", "#C5F596", "#DAEBFF", "#FEDAAE", "#FFF5A2"]}
      recycle={true}
      numberOfPieces={150}
      tweenDuration={70}
      onConfettiComplete={onFinish}
    />
  )
}