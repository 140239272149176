import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import Dialog from "./Dialog"
import Button from "./Button"

interface Props {
  onClose: () => void;
  open: boolean;
}

export default function ResetPasswordDialog({ onClose, open }: Props) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Dialog onClose={onClose} open={open}>
      <div className="space-y-10 text-black">
        <p className="heading">
          {t("pages.account.resetPassword.dialog.heading")}
        </p>

        <p className="font-sentient text-center">
          {t("pages.account.resetPassword.dialog.subtitle")}
        </p>

        <Button
          title={t("shared.signIn")}
          type="submit"
          onClick={() => navigate("/account/sign-in")}
        />
      </div>
    </Dialog>
  )
}
