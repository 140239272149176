import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import useLocalStorage from "react-use/lib/useLocalStorage"
import { useState } from "react"
import Button from "../../components/Button"
import ScrollLayout from "../../components/ScollLayout"

export default function DivePage() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [value] = useLocalStorage<string | null>("selectedTopic", null)
  const [selectedTopic, setSelectedTopic] = useState<number | null>(null)

  const handleClick = () => {
    setTimeout(() => {
      navigate("/onboarding2/area")
    }, 500)
  }

  const handleSelect = (selected: number) => {
    setTimeout(() => {
      setSelectedTopic(selected)
    }, 500)
  }

  function getGenius() {
    switch (value) {
    case "science":
      return t("pages.onboarding.inspiration.einstein")
    case "literature":
      return t("pages.onboarding.inspiration.austen")
    case "economics":
      return t("pages.onboarding.inspiration.smith")
    case "philosophy":
      return t("pages.onboarding.inspiration.aurelius")
    case "art":
      return t("pages.onboarding.inspiration.gogh")
    default:
      return t("pages.onboarding.inspiration.einstein")
    }
  }

  const InitialContent = () => {
    return (
      <div className="custom-container flex h-full flex-col gap-16">
        <div className="shrink-0">
          <h1 className="heading">
            {t("pages.onboarding.dive.heading", { topic: t(`pages.onboarding.topics.${value}`) })}
          </h1>
        </div>

        <div className="justify-top flex grow flex-col items-center space-y-5 pb-8">
          <button
            className="button-rounded-full border-custom-gray"
            onClick={() => handleSelect(0)}
          >
            <span className="mr-1 pr-2">🎉</span>
            {t("pages.onboarding.dive.fun")}
          </button>

          <button
            className="button-rounded-full border-custom-gray"
            onClick={() => handleSelect(1)}
          >
            <span className="mr-1 pr-2">🧠</span>
            {t("pages.onboarding.dive.productivity")}
          </button>

          <button
            className="button-rounded-full border-custom-gray"
            onClick={() => handleSelect(2)}
          >
            <span className="mr-1 pr-2">🎓</span>
            {t("pages.onboarding.dive.education")}
          </button>

          <button
            className="button-rounded-full border-custom-gray"
            onClick={() => handleSelect(3)}
          >
            <span className="mr-1 pr-2">🤓</span>
            {t("pages.onboarding.dive.smartest")}
          </button>

          <button
            className="button-rounded-full border-custom-gray"
            onClick={() => handleSelect(4)}
          >
            <span className="mr-1 pr-2">✨</span>
            {t("pages.onboarding.dive.other")}
          </button>
        </div>
      </div>
    )
  }

  return (
    <div>
      <ScrollLayout>
        <InitialContent />
      </ScrollLayout>
      {selectedTopic !== null && (
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-black/80">
          <div className="flex h-full flex-col text-center">
            {Array.from({ length: selectedTopic + 1 }, (_, index) => (
              <h1 key={index} className="grow"></h1>
            ))}
            <div className="custom-container grow">
              <h1 className="px-4 py-2 text-left text-white">{getGenius()}</h1>
              <div className=" rounded-2xl bg-blue-500 px-4 py-4">
                <h2 className="break-all text-left text-white">
                  {t(`pages.onboarding.dive.topic${selectedTopic}`)}
                </h2>
              </div>
            </div>
            <h1 className="grow"></h1>
            <h1 className="grow"></h1>

            <div className="custom-container sticky bottom-14 max-w-sm shrink-0 bg-transparent">
              <Button
                className="bg-white text-black"
                disabled={false}
                onClick={handleClick}
                title={t("shared.continue")}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
