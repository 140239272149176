import { Navigate, Outlet } from "react-router-dom"
import navigateAction from "../../actions/navigateAction"
import skipOnboardingLoader from "../../loaders/skipOnboardingLoader"
import TopicsPage from "./TopicsPage"
import PersonPage from "./PersonPage"
import BigIdeasPage from "./BigIdeasPage"
import FocusPage from "./FocusPage"
import DescribePage from "./DescribePage"
import ReplaceScrollingPage from "./ReplaceScrollingPage"
import InterestsPage from "./InterestsPage"
import InspirationPage from "./InspirationPage"
import DreamJobPage from "./DreamJobPage"
import InterestTopic1Page from "./InterestTopic1Page"
import InterestTopic2Page from "./InterestTopic2Page"
import InterestTopic3Page from "./InterestTopic3Page"
import InterestTopic4Page from "./InterestTopic4Page"
import LearningGoalPage from "./LearningGoalPage"

const onboardingRoutes = [
  {
    path: "/onboarding",
    element: <Outlet />,
    loader: skipOnboardingLoader,
    children: [
      {
        index: true,
        element: <Navigate to="topics" replace />,
      },
      {
        path: "topics",
        element: <TopicsPage />,
      },
      {
        path: "person",
        element: <PersonPage />,
      },
      {
        element: <BigIdeasPage />,
        path: "ideas",
      },
      {
        element: <FocusPage />,
        path: "focus",
      },
      {
        element: <DescribePage />,
        path: "describe",
      },
      {
        element: <ReplaceScrollingPage />,
        path: "scrolling",
      },
      {
        element: <InterestsPage />,
        path: "interests",
      },
      {
        element: <InspirationPage />,
        path: "inspiration",
      },
      {
        action: navigateAction,
        element: <DreamJobPage />,
        path: "job",
      },
      {
        element: <InterestTopic1Page />,
        path: "topic1",
      },
      {
        element: <InterestTopic2Page />,
        path: "topic2",
      },
      {
        element: <InterestTopic3Page />,
        path: "topic3",
      },
      {
        element: <InterestTopic4Page />,
        path: "topic4",
      },
      {
        element: <LearningGoalPage />,
        path: "goal",
      },
    ],
  },
]

export default onboardingRoutes
