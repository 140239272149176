import { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import AlreadySignedUpDialog from "../../components/AlreadySignedUpDialog"
import Button from "../../components/Button"
import Input from "../../components/Input"
import ScollLayout from "../../components/ScollLayout"
import { trackCompleteRegistrationEvent } from "../../helpers/facebookTracking"
import {
  getPasswordFromSessionStorage,
  removePasswordFromSessionStorage,
} from "../../helpers/sessionStorage"
import { signUp } from "../../helpers/supabase"
import { validateEmail } from "../../helpers/validations"

export default function EmailPage() {
  const [email, setEmail] = useState("")
  const [emailValid, setEmailValid] = useState(true)
  const [password, setPassword] = useState("")
  const [openAlreadySignedUpDialog, setOpenAlreadySignedUpDialog] =
    useState(false)

  const navigate = useNavigate()
  const { t } = useTranslation()

  const isEmailValid = validateEmail(email)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (isEmailValid) {
      const { error } = await signUp({ email, password })

      if (error) {
        if (error.message === "User already registered") {
          setOpenAlreadySignedUpDialog(true)
        } else {
          console.error(error)
        }

        return
      }

      removePasswordFromSessionStorage()

      await trackCompleteRegistrationEvent()

      navigate("/checkout/plans")
    } else {
      setEmailValid(false)
    }
  }

  useEffect(() => {
    const passwordFromSessionStorage = getPasswordFromSessionStorage()

    if (passwordFromSessionStorage) {
      setPassword(passwordFromSessionStorage)
    } else {
      navigate("/sign-up/password")
    }
  }, [navigate])

  return (
    <>
      <ScollLayout>
        <div className="flex h-full flex-col custom-container">
          <div className="grow space-y-6 text-center">
            <h1 className="heading">{t("pages.signUp.email.heading")}</h1>

            <p className={emailValid ? "subheading" : "subheading-error"}>
              {emailValid
                ? t("pages.signUp.email.subheading")
                : t("pages.signUp.email.emailNotValid")}
            </p>
          </div>

          <form
            className="flex grow flex-col justify-between border-custom-gray"
            noValidate
            onSubmit={handleSubmit}
          >
            <div className="space-y-4 font-sentient">
              <Input
                autoFocus
                description={
                  <Trans
                    components={{
                      privacyPolicyLink: (
                        <Link
                          className="underline"
                          target="_blank"
                          to={"https://www.geniusacademy.app/privacy"}
                        />
                      ),
                      termsAndConditionsLink: (
                        <Link
                          className="underline"
                          target="_blank"
                          to={"https://www.geniusacademy.app/terms"}
                        />
                      ),
                    }}
                    i18nKey="pages.signUp.email.text"
                  />
                }
                invalid={!emailValid}
                onChange={(event) => setEmail(event.target.value)}
                placeholder={t("shared.email")}
                type="email"
              />
            </div>

            <Button
              disabled={email.length < 1}
              type="submit"
              title={t("shared.continue")}
            />
          </form>
        </div>
      </ScollLayout>

      <AlreadySignedUpDialog
        open={openAlreadySignedUpDialog}
        onClose={() => setOpenAlreadySignedUpDialog(false)}
      />
    </>
  )
}
