import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import ScrollLayout from "../../components/ScollLayout"

export default function LearningGoalPage() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleClick = () => {
    setTimeout(() => {
      navigate("/sign-up/password")
    }, 500)
  }

  return (
    <ScrollLayout>
      <div className="custom-container flex h-full flex-col gap-16">
        <div className="shrink-0">
          <h1 className="heading">
            {t("pages.onboarding.learningGoal.heading")}
          </h1>
        </div>

        <div className="justify-top flex grow flex-col items-center space-y-5 pb-8">
          <button
            className="button-rounded-full border-custom-gray font-normal"
            onClick={handleClick}
          >
            <span className="mr-1 pr-2 font-bold">
              {t("pages.onboarding.learningGoal.casual")}
            </span>
            {t("pages.onboarding.learningGoal.5min")}
          </button>

          <button
            className="button-rounded-full border-custom-gray font-normal"
            onClick={handleClick}
          >
            <span className="mr-1 pr-2 font-bold">
              {t("pages.onboarding.learningGoal.regular")}
            </span>
            {t("pages.onboarding.learningGoal.10min")}
          </button>

          <button
            className="button-rounded-full border-custom-gray font-normal"
            onClick={handleClick}
          >
            <span className="mr-1 pr-2 font-bold">
              {t("pages.onboarding.learningGoal.serious")}
            </span>
            {t("pages.onboarding.learningGoal.15min")}
          </button>

          <button
            className="button-rounded-full border-custom-gray font-normal"
            onClick={handleClick}
          >
            <span className="mr-1 pr-2 font-bold">
              {t("pages.onboarding.learningGoal.intense")}
            </span>
            {t("pages.onboarding.learningGoal.20min")}
          </button>
        </div>
      </div>
    </ScrollLayout>
  )
}
