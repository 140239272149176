import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import romanticism from "@assets/romanticism.png"
import { useState } from "react"
import ScrollLayout from "../../components/ScollLayout"

export default function InterestTopic1Page() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [position, setPosition] = useState("center")
  const [opacity, setOpacity] = useState(1)

  const moveLeft = () => {
    setPosition("left")
    setOpacity(0)
  }

  const moveRight = () => {
    setPosition("right")
    setOpacity(0)
  }

  const handleClick = () => {
    setTimeout(() => {
      navigate("/onboarding/topic2")
    }, 1000)
  }

  return (
    <ScrollLayout>
      <div className="flex h-full flex-col gap-16">
        <div className="shrink-0">
          <h1 className="heading">
            {t("pages.onboarding.interestTopics.heading")}
          </h1>
          <h1 className="subheading">
            1{t("pages.onboarding.interestTopics.current")}
          </h1>
        </div>
        <div className="flex flex-col items-center justify-center gap-8">
          <div
            className={`transition-all duration-500 ease-in-out ${
              position === "left"
                ? "translate-x-[-100%]"
                : position === "right"
                  ? "translate-x-[100%]"
                  : ""
            }`}
            style={{ opacity }}
          >
            <img
              src={romanticism}
              alt="romanticism"
              className="size-40 object-scale-down w-full"
            />
            <h1 className="heading font-sentient pt-8">
              {t("pages.onboarding.interestTopics.topic1")}
            </h1>
          </div>

        </div>

        <div className="flex flex-row items-center justify-center gap-8 px-16 py-8">
          <button
            onClick={() => {
              moveLeft()
              handleClick()
            }}
            className="rounded-2xl border border-custom-gray px-8 py-6 text-left text-center font-sentient text-lg font-semibold"
          >
            <span className="mr-1 pr-2">👎</span>
            {t("pages.onboarding.interestTopics.no")}
          </button>

          <button
            onClick={() => {
              moveRight()
              handleClick()
            }}
            className="rounded-2xl border border-custom-gray px-8 py-6 text-left text-center font-sentient text-lg font-semibold"
          >
            <span className="mr-1 pr-2">👍</span>
            {t("pages.onboarding.interestTopics.yes")}
          </button>
        </div>
      </div>
    </ScrollLayout>
  )
}
