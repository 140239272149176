import * as Sentry from "@sentry/react"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useRouteError } from "react-router-dom"
import ScrollLayout from "../components/ScollLayout"

interface Error {
  message?: string;
  statusText?: string;
}

export default function ErrorPage() {
  const error = useRouteError() as Error
  const { t } = useTranslation()

  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  console.error(error)

  return (
    <ScrollLayout>
      <div className="flex h-full flex-col justify-center space-y-8 text-center custom-container text-black">
        <h1 className="heading text-center">{t("pages.error.oops")}</h1>

        <p className="subheading">{t("pages.error.anErrorOccurred")}</p>

        <p className="text-center italic">
          {error?.statusText ?? error?.message}
        </p>
      </div>
    </ScrollLayout>
  )
}
