import { useTranslation } from "react-i18next"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import austen from "@assets/austen.png"
import einstein from "@assets/einstein.png"
import gogh from "@assets/gogh.png"
import kafka from "@assets/kafka.png"
import curie from "@assets/curie.png"
import newton from "@assets/newton.png"
import Typewriter from "../../components/Typewriter"
import Button from "../../components/Button"
import ScrollLayout from "../../components/ScollLayout"

export default function Inspiration() {
  const [selected, setSelected] = useState<string | null>(null)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleClick = () => {
    navigate("/onboarding/job")
  }

  const handleSelect = (selected: string) => {
    setTimeout(() => {
      setSelected(selected)
    }, 500)
  }

  function getTitle() {
    return t(`pages.onboarding.inspiration.${selected}`)
  }

  function getImage() {
    switch (selected) {
    case "austen":
      return austen
    case "einstein":
      return einstein
    case "kafka":
      return kafka
    case "gogh":
      return gogh
    case "curie":
      return curie
    case "newton":
      return newton
    default:
      return einstein
    }
  }

  const InitialContent = () => {
    return (
      <main className="flex h-full min-h-dvh flex-col text-black">
        <div className="custom-container top-0 shrink-0 bg-white py-8">
          <h1 className="heading">
            {t("pages.onboarding.inspiration.heading")}
          </h1>
        </div>

        <div className="custom-container flex grow flex-col justify-center py-8">
          <div className="grid grid-cols-2 gap-5 px-8 pb-36">
            <button
              className="flex items-start justify-end"
              onClick={() => handleSelect("austen")}
            >
              <div className="flex flex-col items-center justify-center gap-2">
                <img
                  src={austen}
                  alt="Austen"
                  className="h-40 w-40 object-scale-down"
                />
                <h1 className="font-oswald text-base">
                  {t("pages.onboarding.inspiration.austen")}
                </h1>
              </div>
            </button>

            <button
              className="flex items-start justify-start"
              onClick={() => handleSelect("einstein")}
            >
              <div className="flex flex-col items-center justify-center gap-2">
                <img
                  src={einstein}
                  alt="Einstein"
                  className="h-40 w-40 object-scale-down"
                />
                <h1 className="font-oswald text-base">
                  {t("pages.onboarding.inspiration.einstein")}
                </h1>
              </div>
            </button>

            <button
              className="flex items-start justify-end"
              onClick={() => handleSelect("kafka")}
            >
              <div className="flex flex-col items-center justify-center gap-2">
                <img
                  src={kafka}
                  alt="Kafka"
                  className="h-40 w-40 object-scale-down"
                />
                <h1 className="font-oswald text-base">
                  {t("pages.onboarding.inspiration.kafka")}
                </h1>
              </div>
            </button>

            <button
              className="flex items-start justify-start"
              onClick={() => handleSelect("gogh")}
            >
              <div className="flex flex-col items-center justify-center gap-2">
                <img
                  src={gogh}
                  alt="Gogh"
                  className="h-40 w-40 object-scale-down"
                />
                <h1 className="font-oswald text-base">
                  {t("pages.onboarding.inspiration.gogh")}
                </h1>
              </div>
            </button>

            <button
              className="flex items-start justify-end"
              onClick={() => handleSelect("curie")}
            >
              <div className="flex flex-col items-center justify-center gap-2">
                <img
                  src={curie}
                  alt="curie"
                  className="h-40 w-40 object-scale-down"
                />
                <h1 className="font-oswald text-base">
                  {t("pages.onboarding.inspiration.curie")}
                </h1>
              </div>
            </button>

            <button
              className="flex items-start justify-start"
              onClick={() => handleSelect("newton")}
            >
              <div className="flex flex-col items-center justify-center gap-2">
                <img
                  src={newton}
                  alt="newton"
                  className="h-40 w-40 object-scale-down"
                />
                <h1 className="font-oswald text-base">
                  {t("pages.onboarding.inspiration.newton")}
                </h1>
              </div>
            </button>
          </div>
        </div>
      </main>
    )
  }

  const GeniusContent = () => {
    return (
      <div className="custom-container flex h-full flex-col text-center">
        <div className="flex grow flex-col items-center justify-center gap-8">
          <img
            src={getImage()}
            alt={getTitle()}
            className="size-40 object-scale-down"
          />

          <Typewriter
            text={t(`pages.onboarding.inspiration.${selected}-quote`)}
          />
          <h2 className="subheading">{getTitle()}</h2>
        </div>

        <div className="custom-container max-w-sm shrink-0 py-8">
          <Button onClick={handleClick} title={t("shared.continue")} />
        </div>
      </div>
    )
  }

  const Content = () => {
    if (selected !== null) {
      return <GeniusContent />
    } else {
      return <InitialContent />
    }
  }

  return (
    <ScrollLayout>
      <Content />
    </ScrollLayout>
  )
}
